import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Cookies ,useCookies} from "react-cookie";


// https://redux-toolkit.js.org/api/createAsyncThunk
// https: //blog.logrocket.com/using-redux-toolkits-createasyncthunk/
//  read these for createAsyncThunk
export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
    const response = await fetch("https://jsonplaceholder.typicode.com/users");
    const users = await response.json();
    return users;
});

export const calllogin = createAsyncThunk("user/login", async (val) => {


    console.log('val of calllogin==>', val);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(val)
    };
    console.log("requestOptions.body==>", requestOptions.body)

    const response = await fetch(process.env.REACT_APP_API_URL + "userlogin", requestOptions);
    const users = await response.json();
    console.log('users', users);
    console.log('response==', response);

    return users;
});

export const getipinfo = createAsyncThunk("user/getipinfo", async () => {
    console.log("process.env==>", process.env);

    const response = await fetch(process.env.REACT_APP_API_URL_IP);
    const ipinfo = await response.json();
    console.log('ipinfo', ipinfo);
    console.log('response==', response);

    return ipinfo;
});

const loggedinuserslice = createSlice({
    name: "loggedinuser",
    initialState: {
        userinfo: {},
        usersecret: null,
        usertoken: null,
        loading: false,
        ipinfo: {},
        errormessage: {},
        isLoggedIn:false,
    },
    reducers: {
        getLogedinUserInfo(state, action) {
            console.log("state from getLogedinUserInfo==>", state);
            console.log("action from getLogedinUserInfo==>", action);
        },
        setLogedinUserInfo(state, action) {
            console.log("state from setLogedinUserInfo==>", state);
            console.log("action.payload from setLogedinUserInfo==>", action.payload);
            state.userinfo = action.payload.userinfocookies;
            state.usersecret = action.payload.usersecretcookies;
            state.usertoken = action.payload.usertokencookies;
            state.isLoggedIn = true;
            console.log("state from setLogedinUserInfo after set==>", state);
        },
        logOut(state, action) {
            console.log("state from logOut==>", state);
            console.log("action.payload from logOut==>", action.payload);
            state.userinfo = {};
            state.usersecret = null;
            state.usertoken = null;
            console.log("state from logOut after set==>", state);
        },
        userAdded(state, action) {
            state.entities.push(action.payload);
        },
        login(state, action) {
            console.log("action.payload in login==>", action.payload);
            console.log("state in login==>", state);
            // calllogin()

        },
        userUpdated(state, action) {
            const { id, name, email } = action.payload;
            const existingUser = state.entities.find((user) => user.id === id);
            if (existingUser) {
                existingUser.name = name;
                existingUser.email = email;
            }
        },
        userDeleted(state, action) {
            const { id } = action.payload;
            const existingUser = state.entities.find((user) => user.id === id);
            if (existingUser) {
                state.entities = state.entities.filter((user) => user.id !== id);
            }
        },
    },
    extraReducers: {
        // https://redux-toolkit.js.org/api/createslice   https://stackoverflow.com/questions/66425645/what-is-difference-between-reducers-and-extrareducers-in-redux-toolkit read these links to know more about extrareducers 
        [calllogin.fulfilled]: (state, action) => {
            console.log("calllogin==", calllogin);
            // state.loading = true;
            let payload = action.payload;
            console.log("action.payload fulfilled of calllogin==>", action.payload, payload.status);
            if (payload.status === 'error') {
                state.errormessage = { "message": payload.message }
            }
            if (payload.status === 'success') {
                console.log('payload success', payload);
                state.userinfo = payload.item;
                state.usersecret = payload.secret;
                state.usertoken = payload.token;
                state.isLoggedIn = true;
                console.log("state from success part==>", state);


            }
            // console.log("state in calllogin fulfilled==>", state);
            // console.log("action in calllogin fulfilled==>", action);
        },
        [fetchUsers.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchUsers.fulfilled]: (state, action) => {
            state.loading = false;
            // state.entities = [...state.entities, ...action.payload];
        },
        [fetchUsers.rejected]: (state, action) => {
            state.loading = false;
        },
        [getipinfo.fulfilled]: (state, action) => {
            console.log("getipinfo.fulfilled==>")
            // state.loading = false;
            let ipdata = action.payload;
            ipdata.browser = getbrowserinfo();
            state.ipinfo = ipdata;
            console.log("state of fullfilled from getipinfo==>", state);
            console.log('getipinfo state.ipinfo==>', state.ipinfo, "getipinfo action.payload==>", action.payload)
            // console.log(getbrowserinfo());
        },
    },
});

export const { userAdded, userUpdated, userDeleted, login, getLogedinUserInfo, setLogedinUserInfo , logOut} = loggedinuserslice.actions;

export default loggedinuserslice.reducer;

function getbrowserinfo() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
        return ('Opera');
    }
    else if (navigator.userAgent.indexOf("Edg") !== -1) {
        return ('Edge');
    }
    else if (navigator.userAgent.indexOf("Chrome") !== -1) {
        return ('Chrome');
    }
    else if (navigator.userAgent.indexOf("Safari") !== -1) {
        return ('Safari');
    }
    else if (navigator.userAgent.indexOf("Firefox") !== -1) {
        return ('Firefox');
    }
    else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) //IF IE > 10
    {
        return ('IE');
    }
    else {
        return ('unknown');
    }
}