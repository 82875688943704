import ReactForms from "../reuse/fetures/form/ReactForms"
// import  Blogs  from "./Blogs"
const ReactFormExample = () => {
    return (
        <div>

            <h1>This is Reusable React ReactFormExample  </h1>

            <ReactForms name="dd" val="e3" />
            {/* <Blogs /> */}
        </div>
    );
};

export default ReactFormExample;