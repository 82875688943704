import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies, Cookies } from "react-cookie";
import { login, calllogin, getipinfo, getLogedinUserInfo } from "./loggedin_user";
import store from "../../store";

store.dispatch(getipinfo())
store.dispatch(getLogedinUserInfo())
// console.log("userinfocookies===>",Cookies.get('userinfo'))
// let ipinfo = "";
let tempstore = {};
const Login = () => {
    const isLoggedIn = useSelector((state) => state.loggedinuser.isLoggedIn);
    console.log("isLoggedIn==>", isLoggedIn);
    const dispatch = useDispatch();
    // to navigate
    const navigate = useNavigate();
    // for cookie
    const [userinfocookies, setUserInfoCookie] = useCookies(["userinfo"]);
    const [usersecretcookies, setUserSecretCookie] = useCookies(["usersecret"]);
    const [usertokencookies, setUserTokenCookie] = useCookies(["usertoken"]);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    tempstore = store.getState()

    managenav(tempstore, setUserInfoCookie, setUserSecretCookie, setUserTokenCookie, navigate)


    store.subscribe(() => {
        console.log('subscribe store', store.getState());
        tempstore = store.getState()

        console.log("tempstore.loggedinuser==>", tempstore.loggedinuser);


        managenav(tempstore, setUserInfoCookie, setUserSecretCookie, setUserTokenCookie, navigate)

        // setTimeout(() => {
        //     showForm = 1;
        // }, 0)

    })

    const onSubmit = (data) => {

        console.log("data==>", data);
        let newState = store.getState();
        data.login_data = newState.loggedinuser.ipinfo;

        console.log("data after==>", data, newState);

        console.log("errors==>", errors);
        // dispatch(
        //     login(data)
        //   );

        dispatch(calllogin(data));
    }
    console.log(watch("email")); // watch input value by passing the name of it
    return (

        <div>
            <h1>Welcome To Test React Login Form</h1>
            {tempstore.loggedinuser != null && tempstore.loggedinuser.errormessage != null && tempstore.loggedinuser.errormessage.message != null && <span> {tempstore.loggedinuser.errormessage.message}</span>
            }
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* register your input into the hook by invoking the "register" function */}
                <input defaultValue="" {...register("email", { required: true })} />
                {errors.email && <span>Email Required</span>}

                {/* include validation with required or other standard HTML validation rules */}
                <input type="password" {...register("password", { required: true })} />
                {/* errors will return when field validation fails  */}
                {errors.password && <span>This field is required</span>}

                <input type="submit" />
            </form>
        </div>

    );
};

function managenav(tempstore, setUserInfoCookie, setUserSecretCookie, setUserTokenCookie, navigate) {
    if (tempstore.loggedinuser.userinfo !== undefined && tempstore.loggedinuser.userinfo._id !== null && tempstore.loggedinuser.userinfo._id !== undefined) {
        console.log('tempstore.loggedinuser.userinfo._id==>', tempstore.loggedinuser.userinfo._id);

        console.log('tempstore.loggedinuser.userinfo.type==>', tempstore.loggedinuser.userinfo.type);

        setUserInfoCookie('userinfocookies', JSON.stringify(tempstore.loggedinuser.userinfo), { path: '/' });

        setUserSecretCookie('usersecretcookies', tempstore.loggedinuser.usersecret, { path: '/' });

        setUserTokenCookie('usertokencookies', tempstore.loggedinuser.usertoken, { path: '/' });

        navigate("/"+tempstore.loggedinuser.userinfo.type+"-dashboard", { replace: true });    // navite to another url
    }
}

export default Login;
