import { fetchUsers, userDeleted } from "./usersSlice"; 
import { useDispatch, useSelector } from "react-redux";
//  https://medium.com/@mendes.develop/introduction-on-react-redux-using-hooks-useselector-usedispatch-ef843f1c2561  read these links to know more about react hooks
import store from "../../store";

import { Link } from "react-router-dom";
store.dispatch(fetchUsers());

export function UserList() {
  const dispatch = useDispatch();
  // dispatch(fetchUsers());

  const { entities } = useSelector((state) => state.users);
  const loading = useSelector((state) => state.loading);
  // setTimeout(function () {
  //   const { entities } = useSelector((state) => state.users);
  // }, 3000);

  const handleDelete = (id) => {
    dispatch(userDeleted({ id }));
  };

  return (
    <div className="container">
      <div className="row">
        <h1>Redux CRUD User app</h1>
      </div>
      <div className="row">
        <div className="two columns">
          {/* calling fetch user excluding  store so new http request made  */}
          <button
            onClick={() => dispatch(fetchUsers())}
            className="button-primary"
          >
            Load users
          </button>
        </div>
        <div className="two columns">
          <Link to="/add-user">
            <button className="button-primary">Add user</button>
          </Link>
        </div>
      </div>
      <div className="row">
        {loading ? (
          "Loading..."
        ) : (
          <table className="u-full-width">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {entities.length &&
                entities.map(({ id, fname, lname, email }, i) => (
                  
                  <tr key={i}>
                    <td>{id}</td>
                    <td>{fname + lname}</td>
                    <td>{email}</td>
                    <td>
                      <button onClick={() => handleDelete(id)}>Delete</button>
                      <Link to={`/edit-user/${id}`}>
                        <button>Edit {id}</button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
