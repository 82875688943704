import * as React from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { useDispatch } from 'react-redux';

import { logOut } from '../pages/login/loggedin_user';
import { fetchTrainings } from '../features/Training/training_reducer';
import { ListingTraining } from '../features/Training/listing_Training'



function TopMenu() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectMenu, setselectMenu] = React.useState(2);
    const open = Boolean(anchorEl);
    const open1 = Boolean(anchorEl);
    const handleClick = (val, event) => {
        console.log("val==>", val);
        console.log("event==>", event);
        setselectMenu(val);
        console.log('event.currentTarget==>', event.currentTarget);
        console.log('All==>', event.currentTarget, open, open1, anchorEl, selectMenu, val);
        setAnchorEl(event.currentTarget);
        console.log('All after==>', anchorEl, selectMenu, val);

    };
    const handleClose = () => {
        console.log('before close ..', open, open1, anchorEl, selectMenu)
        setAnchorEl(null);
    };
    const handleLogout = () => {
        dispatch(logOut());
        const cookies = new Cookies();
        cookies.remove("userinfocookies", { path: '/' });
        cookies.remove("usersecretcookies", { path: '/' });
        cookies.remove("usertokencookies", { path: '/' });
        console.log("cookies in topnav==>", cookies.getAll());
    }

    const trainingList = () => {
        console.log("training list");
        dispatch(fetchTrainings());
        navigate('/list-training', { replace: true })
    }

    return (
        <div>
            <Button
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(evt) => handleClick(1, evt)}
            >
                {/* (evt) => this.handleRemove(id, evt) handleClick */}
                Dashboard
            </Button>

            <Button
                id="fade-button-2"
            >
                Users
            </Button>

            <Button
                id="fade-button-2"
                aria-controls={open1 ? 'fade-menu-test' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
                onClick={(evt) => handleClick(3, evt)}
            >
                Training Management
            </Button>

            <Button
                id="fade-button-2"
                aria-controls={open1 ? 'fade-menu-test' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
                onClick={(evt) => handleClick(2, evt)}
            >
                Vat Management
            </Button>

            <Button
                id="fade-button-2"
            >
                <Link to="/login"> Login </Link>{" "}
            </Button>

            <Button
                id="fade-button-2"
                onClick={() => handleLogout()}
            >
                Logout
            </Button>

            {selectMenu === 3 &&

                <Menu
                    id="fade-menu-test"
                    MenuListProps={{
                        'aria-labelledby': 'fade-button-2',
                    }}
                    anchorEl={anchorEl}
                    open={open1}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <MenuItem onClick={handleClose}> <Link to="/list-training" onClick={() => trainingList()}> Training List </Link>{" "}</MenuItem>
                    <MenuItem onClick={handleClose}>Lessons</MenuItem>
                </Menu>
            }

            {selectMenu === 2 &&

                <Menu
                    id="fade-menu-test"
                    MenuListProps={{
                        'aria-labelledby': 'fade-button-2',
                    }}
                    anchorEl={anchorEl}
                    open={open1}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <MenuItem onClick={handleClose}>Owner</MenuItem>
                    <MenuItem onClick={handleClose}>Manager</MenuItem>
                    <MenuItem onClick={handleClose}>Area-Manager</MenuItem>
                </Menu>
            }

            {selectMenu === 1 &&

                <Menu
                    id="fade-menu"
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                </Menu>

            }
        </div>
    );
}

export default withCookies(TopMenu);