import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// https://redux-toolkit.js.org/api/createAsyncThunk
// https: //blog.logrocket.com/using-redux-toolkits-createasyncthunk/
//  read these for createAsyncThunk
export const testAsyncThunk = createAsyncThunk("users/fetchUsers", async() => {
    const response = await fetch("https://jsonplaceholder.typicode.com/users");
    const data = await response.json();
    return data;
});

const reuseSlice = createSlice({
    name: "reuse",
    initialState: {
        entities: [],
        // loading: false,
    },
    reducers: {
        dataAdded(state, action) {
            state.entities.push(action.payload);
            console.log("action.payload from uer added==>",action.payload);
        },
        dataUpdated(state, action) {
            const { id, name, email } = action.payload;
            const existingUser = state.entities.find((user) => user.id === id);
            if (existingUser) {
                existingUser.name = name;
                existingUser.email = email;
            }
        },
        dataDeleted(state, action) {
            const { id } = action.payload;
            const existingUser = state.entities.find((user) => user.id === id);
            if (existingUser) {
                state.entities = state.entities.filter((user) => user.id !== id);
            }
        },
    },
    extraReducers: {
        // https://redux-toolkit.js.org/api/createslice   https://stackoverflow.com/questions/66425645/what-is-difference-between-reducers-and-extrareducers-in-redux-toolkit read these links to know more about extrareducers 
        [testAsyncThunk.pending]: (state, action) => {
            state.loading = true;
        },
        [testAsyncThunk.fulfilled]: (state, action) => {
            state.loading = false;
            state.entities = [...state.entities, ...action.payload];
            console.log("state.entities from fetchusers==>",state.entities);
            console.log("action.payload from fetchusers==>",action.payload);
        },
        [testAsyncThunk.rejected]: (state, action) => {
            state.loading = false;
        },
    },
});

export const { dataAdded, dataUpdated, dataDeleted } = reuseSlice.actions;

export default reuseSlice.reducer;