import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useCookies } from "react-cookie";
import {
  getLoggedinUserInfo,
  userDeleted,
} from "./../features/users/loggedinUsersSlice";
import { useDispatch, useSelector } from "react-redux";

const Cookie = () => {
  const dispatch = useDispatch();
  // dispatch(getLoggedinUserInfo());

  const [cookies, setCookie] = useCookies(["name"]);
  function handleChange(e) {
    console.log("handle change called", e.target.value);
    setCookie("name", e.target.value, { path: "/" });
  }

  console.log("cookies", cookies);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title> My Title Cookie </title>{" "}
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>{" "}
      <h1> This is Cookie </h1>{" "}
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="outlined-basic"
          onChange={(e) => {
            handleChange(e);
          }}
          label="Outlined"
          variant="outlined"
        />
        <TextField id="filled-basic" label="Filled" variant="filled" />
        <TextField id="standard-basic" label="Standard" variant="standard" />
      </Box>
    </div>
  );
};

export default Cookie;
