import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import store from "../../store";
import Axios from "axios";
import { fetchTrainings } from "./training_reducer";
import { Link } from "react-router-dom";



// store.dispatch(fetchTrainings());

let tempstore = {};
const ListingTraining = () => {
    const dispatch = useDispatch();

    // dispatch(fetchTrainings());

    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const loading = useSelector((state) => state.loading);
    const { state } = useSelector((state) => state);
    console.log("state==", state);
    const trainingloading = useSelector((state) => console.log("state.training.trainingData.res==", state.training.trainingData.res))



    console.log("training calling from topnav");
    // useEffect(() => {
    //     // isLoaded = false;
    //     console.log(isLoaded, "setIsLoaded");
    //     setIsLoaded(false);
    //     let req_body = {}
    //     Axios.post(
    //         process.env.REACT_APP_API_URL + "api2/gettrainingmanagement",
    //         req_body
    //     ).then((response) => {
    //         console.log(
    //             "response==", response
    //         );
    //         console.log(
    //             "response.data.res==", response.data.res
    //         );
    //         setIsLoaded(true);
    //         setItems(response.data.res);
    //         console.log(isLoaded, "setIsLoaded");
    //     });
    // }, []);





    // let req_body = {}
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify(req_body)
    // };
    // const response = await fetch(process.env.REACT_APP_API_URL + "api2/gettrainingmanagement", requestOptions);
    // const trainings = await response.json();
    // console.log("trainings==", trainings);

    // const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    // store.subscribe(() => {
    //     console.log('subscribe store', store.getState());
    //     tempstore = store.getState()
    // })

    // fetchTrainings()y
    return (

        <div className="container">
            <div className="row">
                <h1>Training List Works</h1>
            </div>
            <div className="row">
                <div className="two columns">
                    {/* calling fetch user excluding  store so new http request made  */}
                    <button
                        onClick={() => dispatch(fetchTrainings())}
                        className="button-primary"
                    >
                        Load Trainings
                    </button>
                </div>
                <div className="two columns">
                    <Link to="/add-training">
                        <button className="button-primary">Add Training</button>
                    </Link>
                </div>
            </div>
            <div className="row">
                {trainingloading ? (
                    "Loading..."
                ) : (
                    <table className="u-full-width">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {trainingloading && trainingloading.length &&
                                trainingloading.map(({ _id, catagory_name, description, status }, i) => (
                                    <tr key={i}>
                                        <td>{catagory_name}</td>
                                        <td>{description}</td>
                                        <td>{status}</td>
                                        <td>
                                            {/* <button onClick={() => handleDelete(id)}>Delete</button> */}
                                            <Link to={`/edit-training/${_id}`}>
                                                <button>Edit {_id}</button>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    )

}


export default ListingTraining;
