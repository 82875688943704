import { Helmet } from "react-helmet";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value) {
  return `${value}°C`;
}

const Mui = () => {
  return (
    <div>
      <h1> This example Mui </h1>{" "}
      <Button variant="contained">Hello World</Button>
      <Box sx={{ width: 300 }}>
        <Slider
          aria-label="Temperature"
          defaultValue={30}
          getAriaValueText={valuetext}
          valueLabelDisplay="auto"
          step={10}
          marks
          min={10}
          max={110}
        />
        <Slider defaultValue={30} step={10} marks min={10} max={110} disabled />
        <Slider defaultValue={30} step={10} marks min={10} max={110} />
      </Box>
    </div>
  );
};

export default Mui;
