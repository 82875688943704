import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Forms from "../pages/Forms";
import Layout from "../pages/Layout";
import Mui from "../pages/Mui";
import Home from "../pages/Home";
import Blogs from "../pages/Blogs";
import Contact from "../pages/Contact";
import Cookie from "../pages/Cookie";
import NoPage from "../pages/NoPage";
import Login from "../pages/login/login";
import ReactFormExample from "../pages/reactForm";
import AdminDashboard from '../features/Dashboard/admin';
import AreaManagerDashboard from '../features/Dashboard/area_manager';
import ManagerDashboard from '../features/Dashboard/manager';
import OwnerDashboard from '../features/Dashboard/owner';
import AddTraining from '../features/Training/add_training';
import EditTraining from '../features/Training/edit_training';
import ListingTraining from '../features/Training/listing_Training';
import { AddUser } from "../features/users/AddUser";
import { EditUser } from "../features/users/EditUser";
import { UserList } from "../features/users/UserList";

const GetRoutes = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="userlist" element={<UserList />} />{" "}
          <Route path="add-user" element={<AddUser />} />{" "}
          <Route path="edit-user/:id" element={<EditUser />} />{" "}
          <Route index element={<Home />} />{" "}
          <Route path="blogs" element={<Blogs />} />{" "}
          <Route path="reactform" element={<ReactFormExample />} />{" "}
          <Route path="contact" element={<Contact />} />{" "}
          <Route path="forms" element={<Forms />} />{" "}
          <Route path="mui" element={<Mui />} />{" "}
          <Route path="cookie" element={<Cookie />} />{" "}
          <Route path="login" element={<Login />} />{" "}
          <Route path="admin-dashboard" element={<AdminDashboard />} />{" "}
          <Route path="areamanager-dashboard" element={<AreaManagerDashboard />} />{" "}
          <Route path="manager-dashboard" element={<ManagerDashboard />} />{" "}
          <Route path="owner-dashboard" element={<OwnerDashboard />} />{" "}
          <Route path="add-training" element={<AddTraining />} />{" "}
          <Route path="edit-training/_id" element={<EditTraining />} />{" "}
          <Route path="list-training" element={<ListingTraining />} />{" "}
          <Route path="*" element={<NoPage />} />{" "}
        </Route>
      </Routes>
    </BrowserRouter>
  );

}

export default GetRoutes;