import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// https://redux-toolkit.js.org/api/createAsyncThunk
// https: //blog.logrocket.com/using-redux-toolkits-createasyncthunk/
//  read these for createAsyncThunk
export const fetchTrainings = createAsyncThunk("training/listtrainings", async () => {
    let req_body = {}
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api2/gettrainingmanagement", requestOptions);
    const trainings = await response.json();
    console.log("trainings==", trainings);
    return trainings;
});
// export const calllogin = createAsyncThunk("user/login", async (val) => {


//     console.log('val of calllogin==>', val);
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(val)
//     };
//     console.log("requestOptions.body==>", requestOptions.body)

//     const response = await fetch(process.env.REACT_APP_API_URL + "addorupdatetrainingmanagement", requestOptions);
//     const users = await response.json();
//     console.log('users', users);
//     console.log('response==', response);

//     return users;


// });

// export const getipinfo = createAsyncThunk("user/getipinfo", async () => {
//     console.log("process.env==>", process.env);

//     const response = await fetch(process.env.REACT_APP_API_URL_IP);
//     const ipinfo = await response.json();
//     console.log('ipinfo', ipinfo);
//     console.log('response==', response);

//     return ipinfo;
// });

const listingTraining = createSlice({
    name: "trainings",
    initialState: {
        trainingData: {},
        entities: [],
        loading: false,
        ipinfo: {},
        errormessage: {}
    },
    reducers: {
        AddTraining(state, action) {
            state.entities.push(action.payload);
        },

        EditTraining(state, action) {
            const { id, name, email } = action.payload;
            const existingUser = state.entities.find((user) => user.id === id);
            if (existingUser) {
                existingUser.name = name;
                existingUser.email = email;
            }
        },
        // userDeleted(state, action) {
        //     const { id } = action.payload;
        //     const existingUser = state.entities.find((user) => user.id === id);
        //     if (existingUser) {
        //         state.entities = state.entities.filter((user) => user.id !== id);
        //     }
        // },
    },
    extraReducers: {
        [fetchTrainings.fulfilled]: (state, action) => {
            console.log("fetchTrainings.action==>", action)
            state.trainingData = action.payload;
        },
    },
});

export const { AddTraining, EditTraining, } = listingTraining.actions;

export default listingTraining.reducer;

