import { Outlet, Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import  TopMenu  from "./../layout/topnav";
import {setLogedinUserInfo} from "./../pages/login/loggedin_user";
import store from "../store.js";

let storedata = null;

const Layout = () => {
  const { pathname } = useLocation();
  console.log("pathname==>", pathname);

  store.subscribe(() => {
    storedata = store.getState();
    console.log("storedata from layout==>", storedata);
  });

  return (
    <>
      {pathname !== "/login" && (
        <div>< TopMenu /></div>
      )}
      {(pathname !== "/login" && pathname !== "/list-training") && (
        <nav>
          <ul>
            <li>
              <Link to="/"> Home </Link>{" "}
            </li>{" "}
            <li>
              <Link to="/blogs"> Blogs </Link>{" "}
            </li>{" "}
            <li>
              <Link to="/contact"> Contact </Link>{" "}
            </li>{" "}
            <li>
              <Link to="/userlist"> Users </Link>{" "}
            </li>{" "}
            <li>
              <Link to="/forms"> Forms </Link>{" "}
            </li>{" "}
            <li>
              <Link to="/reactform"> React Forms </Link>{" "}
            </li>{" "}
            {pathname !== "/mui" && (
              <li>
                <Link to="/mui"> Mui </Link>{" "}
              </li>
            )}
            <li>
              <Link to="/cookie"> Cookie </Link>{" "}
            </li>{" "}
          </ul>{" "}
        </nav>
      )}
      <Outlet />
    </>
  );
};

export default withCookies(Layout);
