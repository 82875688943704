import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useCookies } from "react-cookie";


// https://redux-toolkit.js.org/api/createAsyncThunk
// https: //blog.logrocket.com/using-redux-toolkits-createasyncthunk/
//  read these for createAsyncThunk
export const getLoggedinUserInfo = () => {
    console.log('fff');
};

const loggedinuserSlice = createSlice({
    name: "users",
    initialState: {
        entities: [],
        loading: false,
    },
    reducers: {
        userAdded(state, action) {
            state.entities.push(action.payload);
        },
        userUpdated(state, action) {
            const { id, name, email } = action.payload;
            const existingUser = state.entities.find((user) => user.id === id);
            if (existingUser) {
                existingUser.name = name;
                existingUser.email = email;
            }
        },
        userDeleted(state, action) {
            const { id } = action.payload;
            const existingUser = state.entities.find((user) => user.id === id);
            if (existingUser) {
                state.entities = state.entities.filter((user) => user.id !== id);
            }
        },
    },
    extraReducers: {
        // https://redux-toolkit.js.org/api/createslice   https://stackoverflow.com/questions/66425645/what-is-difference-between-reducers-and-extrareducers-in-redux-toolkit read these links to know more about extrareducers 

    },
});

export const { userAdded, userUpdated, userDeleted } = loggedinuserSlice.actions;

export default loggedinuserSlice.reducer;