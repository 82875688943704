// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import ReactDOM from "react-dom";
import { Provider} from "react-redux";
import store from "./store";
import GetRoutes from "./config/routes";
// store.dispatch(fetchUsers());
import { CookiesProvider,withCookies, Cookies } from "react-cookie";
import {setLogedinUserInfo} from "./pages/login/loggedin_user";


const cookies = new Cookies();
console.log("cookies in layout==>", cookies.getAll());

const getcookies = cookies.getAll();
console.log("getcookies from index==>",getcookies);
if(getcookies !== null && getcookies !== undefined){
  store.dispatch(setLogedinUserInfo(getcookies));
}

 function App() {
  console.log("env==",process.env);

  return (
    <CookiesProvider>
      <Provider store={store}>
        <GetRoutes />
      </Provider>
    </CookiesProvider>
  );
}

export default withCookies (App)
ReactDOM.render(<App />, document.getElementById("root"));
