const AreaManagerDashboard = () => {

    return (
        <div>
      
      <h1>This is Area Manager Dashboard </h1>
    </div>
    )
    
};

export default AreaManagerDashboard;