import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "./features/users/usersSlice";
import reuseReducer from "./reuse/redux/reuseSlice";
import loggedinuserReducer from "./pages/login/loggedin_user";
import trainingReducer from "./features/Training/training_reducer";

export default configureStore({
    reducer: {
        users: usersReducer,
        loggedinuser: loggedinuserReducer,
        training:trainingReducer,
        reuse:reuseReducer
    },
});