import React from "react";
// import Autocomplete from "react-autocomplete";
import { useForm, Controller } from "react-hook-form";
// import Input from "@mui/core/Input";
import { useState } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import Checkbox from "@mui/material/Checkbox";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import { createTheme, ThemeProvider } from '@mui/material/styles'
import MUIRichTextEditor from 'mui-rte'
// import * as draft from "draft-convert"
import {stateToHTML} from 'draft-js-export-html';



const ReactForms = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm();
  const onSubmit = (data) => console.log(data);
  const aval = "";
  // const autoLabel1 = "";
  const [aVal, setaVal] = useState("");
  const [forErrorVal, setForErrorVal] = useState({});
  const [editorState, setEdititState] = useState( EditorState.createEmpty());
  const [autoLabel1, setautoLabel1] = useState("");
  let auto1Val = watch("auto1", "");
  // watch('firstname',"loi");
  setValue("checkValue", false); 
  setValue("radioValue", ""); 
  // setValue("selectValue2", [""]); 
  
  // radioValue
  let muiformvals = {
    textValue1: "",
    checkValue:false
  };
  let formerrors={
    textValue2:{
      required:"This field is required",
      min:"must be more than 5"
    }

  }
  // let textValue1 = watch("textValue", 'e');
  // const

  const myTheme = createTheme({
    // Set up your custom MUI theme here
})



// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
  {
    label: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  { label: 'The Good, the Bad and the Ugly', year: 1966 },
  { label: 'Fight Club', year: 1999 },
  {
    label: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    label: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  },
  { label: 'Forrest Gump', year: 1994 },
  { label: 'Inception', year: 2010 },
  {
    label: 'The Lord of the Rings: The Two Towers',
    year: 2002,
  },
  { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { label: 'Goodfellas', year: 1990 },
  { label: 'The Matrix', year: 1999 },
  { label: 'Seven Samurai', year: 1954 },
  {
    label: 'Star Wars: Episode IV - A New Hope',
    year: 1977,
  },
  { label: 'City of God', year: 2002 },
  { label: 'Se7en', year: 1995 },
  { label: 'The Silence of the Lambs', year: 1991 },
  { label: "It's a Wonderful Life", year: 1946 },
  { label: 'Life Is Beautiful', year: 1997 },
  { label: 'The Usual Suspects', year: 1995 },
  { label: 'Léon: The Professional', year: 1994 },
  { label: 'Spirited Away', year: 2001 },
  { label: 'Saving Private Ryan', year: 1998 },
  { label: 'Once Upon a Time in the West', year: 1968 },
  { label: 'American History X', year: 1998 },
  { label: 'Interstellar', year: 2014 },
  { label: 'Casablanca', year: 1942 },
  { label: 'City Lights', year: 1931 },
  { label: 'Psycho', year: 1960 },
  { label: 'The Green Mile', year: 1999 },
  { label: 'The Intouchables', year: 2011 },
  { label: 'Modern Times', year: 1936 },
  { label: 'Raiders of the Lost Ark', year: 1981 },
  { label: 'Rear Window', year: 1954 },
  { label: 'The Pianist', year: 2002 },
  { label: 'The Departed', year: 2006 },
  { label: 'Terminator 2: Judgment Day', year: 1991 },
  { label: 'Back to the Future', year: 1985 },
  { label: 'Whiplash', year: 2014 },
  { label: 'Gladiator', year: 2000 },
  { label: 'Memento', year: 2000 },
  { label: 'The Prestige', year: 2006 },
  { label: 'The Lion King', year: 1994 },
  { label: 'Apocalypse Now', year: 1979 },
  { label: 'Alien', year: 1979 },
  { label: 'Sunset Boulevard', year: 1950 },
  {
    label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    year: 1964,
  },
  { label: 'The Great Dictator', year: 1940 },
  { label: 'Cinema Paradiso', year: 1988 },
  { label: 'The Lives of Others', year: 2006 },
  { label: 'Grave of the Fireflies', year: 1988 },
  { label: 'Paths of Glory', year: 1957 },
  { label: 'Django Unchained', year: 2012 },
  { label: 'The Shining', year: 1980 },
  { label: 'WALL·E', year: 2008 },
  { label: 'American Beauty', year: 1999 },
  { label: 'The Dark Knight Rises', year: 2012 },
  { label: 'Princess Mononoke', year: 1997 },
  { label: 'Aliens', year: 1986 },
  { label: 'Oldboy', year: 2003 },
  { label: 'Once Upon a Time in America', year: 1984 },
  { label: 'Witness for the Prosecution', year: 1957 },
  { label: 'Das Boot', year: 1981 },
  { label: 'Citizen Kane', year: 1941 },
  { label: 'North by Northwest', year: 1959 },
  { label: 'Vertigo', year: 1958 },
  {
    label: 'Star Wars: Episode VI - Return of the Jedi',
    year: 1983,
  },
  { label: 'Reservoir Dogs', year: 1992 },
  { label: 'Braveheart', year: 1995 },
  { label: 'M', year: 1931 },
  { label: 'Requiem for a Dream', year: 2000 },
  { label: 'Amélie', year: 2001 },
  { label: 'A Clockwork Orange', year: 1971 },
  { label: 'Like Stars on Earth', year: 2007 },
  { label: 'Taxi Driver', year: 1976 },
  { label: 'Lawrence of Arabia', year: 1962 },
  { label: 'Double Indemnity', year: 1944 },
  {
    label: 'Eternal Sunshine of the Spotless Mind',
    year: 2004,
  },
  { label: 'Amadeus', year: 1984 },
  { label: 'To Kill a Mockingbird', year: 1962 },
  { label: 'Toy Story 3', year: 2010 },
  { label: 'Logan', year: 2017 },
  { label: 'Full Metal Jacket', year: 1987 },
  { label: 'Dangal', year: 2016 },
  { label: 'The Sting', year: 1973 },
  { label: '2001: A Space Odyssey', year: 1968 },
  { label: "Singin' in the Rain", year: 1952 },
  { label: 'Toy Story', year: 1995 },
  { label: 'Bicycle Thieves', year: 1948 },
  { label: 'The Kid', year: 1921 },
  { label: 'Inglourious Basterds', year: 2009 },
  { label: 'Snatch', year: 2000 },
  { label: '3 Idiots', year: 2009 },
  { label: 'Monty Python and the Holy Grail', year: 1975 },
];



  console.log("auto1Val watch ", auto1Val);
  const autoItems = [
    { key: 1, label: "apple" },
    { key: 2, label: "banana" },
    { key: 3, label: "pear" },
  ];



  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log(
        "uf",
        "value",
        value,
        "name",
        name,
        "type",
        type,
        "muiformvals",
        muiformvals
      );
      if (name == "auto1") {
        //    setautoLabel1("kk");
        for (let b in autoItems) {
          if (autoItems[b].key == value.auto1) {
            setautoLabel1(autoItems[b].label);
            console.log(autoItems[b], "autoitems");
            //   autoLabel1=autoItems[b];
          }
        }
      }
      console.log("errors", errors);
      for(let n in errors){
        console.log("error loop", n, errors[n]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
    setValue("auto1", "");
  };

  const save = (data) => {
    console.log(data,'save',data.getPlainText());
  };


  const onEditorChange = event => {
    const plainText = event.getCurrentContent().getPlainText() // for plain text
    console.log('plainText',plainText);
    console.log('content ',event.getCurrentContent());
    console.log('content html  ',stateToHTML(event.getCurrentContent()));
    
    setValue('muiEditorValue',stateToHTML(event.getCurrentContent()));
    // convertToHTML
    // const rteContent = convertToRaw(event.getCurrentContent())) // for rte content with text formating
    // rteContent && setValue(JSON.stringify(rteContent)) // store your rteContent to state
  }
  return (
    <>
      <div>
        <h1>This is Reusable React </h1>
        <div>{props.name}</div>
        <div>{props.val}</div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <h5>General input with required and maxlength validation </h5>
        <input
          className="firstname"
          {...register("firstName", { required: true, maxLength: 20 })}
        />
        {errors.firstName?.type === "required" && "First name is required"}
        {errors.firstName?.type === "maxLength" &&
          "Max length should be less than 20 "}
        <br />
        <h5>General input with pattern validation </h5>
        <input {...register("lastName", { pattern: /^[A-Za-z]+$/i })} />
        {errors.lastName?.type === "pattern" &&
          "Last name is not in correct format "}
        <br />
        <h5>General number input with max n min validation </h5>
        <input
          type="number"
          {...register("age", { required: true, min: 18, max: 99 })}
        />
        {errors.age?.type === "min" && "Age min error "}
        {errors.age?.type === "max" && "Age max error "}
        {errors.age?.type === "required" && "required error "}
        <br />
        <h5>General select input with required validation </h5>
        <select defaultValue="" {...register("gender", { required: true })}>
          <option value="">Select Single option</option>
          <option value="female">female</option>
          <option value="male">male</option>
          <option value="other">other</option>
        </select>
        {errors.gender?.type === "required" && <p>selectbox error</p>}
        <br />
        <h5>General select input multiple with pattern validation </h5>
        <select multiple {...register("mgender", { required: true })}>
          <option value="">Select Single option</option>
          <option value="female">female</option>
          <option value="male">male</option>
          <option value="other">other</option>
        </select>
        {errors.mgender?.type === "required" && <p>selectbox error</p>}
        <br />
        <h5>General checkbox input with required validation </h5>
        <label>
          <input
            {...register("checkbox1", { required: true })}
            value={true}
            type="checkbox"
          />
          Example 1 (basic checkbox)
        </label>
        {errors.checkbox1?.type === "required" && <p>checkbox1 error</p>}
        <br />
        <h5>General radio input with pattern validation </h5>
        <label>
          <input
            {...register("radio1", { required: true })}
            value="a"
            type="radio"
          />
          Example 1 (radio input)
        </label>
        <label>
          <input
            {...register("radio1", { required: true })}
            value="b"
            type="radio"
          />
          Example 1 (radio input)
        </label>
        {errors.radio1?.type === "required" && <p>radio1 error</p>}
        <br />
        <br />
        <h5>General auto complete input with out validation </h5>
        <input
          // type="hidden"
          placeholder="auto1"
          className="auto1"
          {...register("auto1", {})}
        />
        {/* <Autocomplete
          getItemValue={(item) => item.key.toString()}
          items={autoItems}
          shouldItemRender={(item, value) =>
            item.label.toString().toLowerCase().indexOf(aVal.toLowerCase()) > -1
          }
          // getItemValue={item => item.label}
          renderItem={(item, isHighlighted) => (
            <div
              key={item.key}
              style={{ background: isHighlighted ? "lightgray" : "white" }}
            >
              {item.label}
            </div>
          )}
          value={aVal}
          onChange={(e) => {
            console.log(e.target.value, aval, "target", e.target);
            setaVal(e.target.value);
            //   setautoLabel1(e.target.value);

            console.log("dd", aVal);
          }}
          onSelect={(val) => {
            //   setaVal(val);
            setaVal("");
            console.log("val", val, "aval", aval);
            setValue("auto1", val);
          }}
        /> */}
        {autoLabel1 !== "" && (
          <Stack direction="row" spacing={1}>
            {/* <Chip label={auto1Val} variant="outlined" /> */}
            <Chip
              label={autoLabel1}
              variant="outlined"
              onDelete={handleDelete}
            />
          </Stack>
        )}{" "}
        <br />
        <h5>General text areainput with required and maxlength validation </h5>
        <textarea
          rows="10"
          className="firstname"
          {...register("textarea1", { required: true, maxLength: 20 })}
        />
        {errors.textarea1?.type === "required" && "textarea1  is required"}
        {/* ui  */}
        <br />
        <h5>Mui input with pattern validation </h5>
        <Controller
          name={"textValue"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={muiformvals.textValue}
              label={"Text Value"}
            />
          )}
        />
        <br />
        <h5>Mui input with out TextField </h5>
        <Controller
          name="firstName1"
          control={control}
          render={({ field }) => <Input {...field} />}
        />
        <br />
        <h5>Mui text input with validation </h5>
        <Controller
          name={"textValue2"}
          rules={{ required: true, min: 5 }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              error={
                Object.keys(errors).length > 0 && errors.textValue2 != null
              }
              onChange={onChange}
              value={muiformvals.textValue2}
              label={"Text Value with validation"}
              helperText={
                errors.textValue2
                  ? formerrors["textValue2"][errors.textValue2.type]
                  : ""
              }
            />
          )}
        />
        <br />
        <h5>Mui checkbox</h5>
        <Controller
          name={"checkValue"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={getValues("checkValue") ? true : false}
              value={muiformvals.checkValue}
              onChange={(e) => {
                console.log(
                  e.target.value,
                  e.target.checked,
                  "target",
                  e.target
                );
                setValue("checkValue", e.target.checked);
                // console.log("dd", aVal);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          )}
        />
        <br />
        <h5>Mui Radio </h5>
        <Controller
          name={"radioValue"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Gender
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={getValues("radioValue") ? getValues("radioValue") : ""}
                onChange={(e) => {
                  console.log(e.target.value, "target radio ", e.target);
                  setValue("radioValue", e.target.value);
                  // console.log("dd", aVal);
                }}
              >
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
              </RadioGroup>
            </FormControl>
          )}
        />
        <br />
        <h5>Mui select </h5>
        <Controller
          name={"selectValue"}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              error={
                Object.keys(errors).length > 0 && errors.selectValue != null
              }
            >
              <Select
                // value={10}
                value={getValues("selectValue") ? getValues("selectValue") : ""}
                onChange={(e) => {
                  console.log(e.target.value, "target radio ", e.target);
                  setValue("selectValue", e.target.value);
                  // console.log("dd", aVal);
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              {errors.selectValue !== undefined && (
                <FormHelperText>select required</FormHelperText>
              )}
            </FormControl>
          )}
        />
        <br />
        <h5>Mui select Mutiple </h5>
        <Controller
          name={"selectValue2"}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              error={
                Object.keys(errors).length > 0 && errors.selectValue2 != null
              }
            >
              <Select
                multiple
                // value={10}
                value={
                  getValues("selectValue2") ? getValues("selectValue2") : [""]
                }
                onChange={(e) => {
                  console.log(e.target.value, "target multi select ", e.target);
                  const index = e.target.value.indexOf("");
                  if (index > -1) {
                    e.target.value.splice(index, 1); // 2nd parameter means remove one item only
                  }
                  setValue("selectValue2", e.target.value);
                  // console.log("dd", aVal);
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
                <MenuItem value={40}>Forty</MenuItem>
                <MenuItem value={50}>Fifty</MenuItem>
              </Select>
              {errors.selectValue2 !== undefined && (
                <FormHelperText>select required</FormHelperText>
              )}
            </FormControl>
          )}
        />
        <br />
        <h5>Mui Switch </h5>
        <Controller
          name={"switchValue"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Switch
              // checked={checked}
              checked={getValues("switchValue") ? true : false}
              // onChange={handleChange}
              onChange={(e) => {
                console.log(
                  e.target.value,
                  e.target.checked,
                  "target",
                  e.target
                );
                setValue("switchValue", e.target.checked);
                // console.log("dd", aVal);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          )}
        />
        <br />
        <h5>Mui Auto Complete </h5>
        <Controller
          name={"autoValue"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              multiple
              disablePortal
              onChange={(event, newValue) => {
                // setValue(newValue);
                console.log("newValue", newValue);
                setValue("autoValue", newValue);
              }}
              id="combo-box-demo"
              options={top100Films}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Movie" />}
            />
          )}
        />
        <br />
        <h5>wsywig editor </h5>
        <Controller
          name={"reactEditor"}
          control={control}
          render={({ field: { onChange, value } }) => (
        <Editor
          // editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={(event) => {
            // setValue(newValue);
            console.log("editor", event,event.getCurrentContent(),'html ',stateToHTML(event.getCurrentContent()));
            setValue(stateToHTML(event.getCurrentContent()))
            // setValue("autoValue", newValue);
          }}
        />
          )}
          />


<Controller
          name={"muiEditorValue"}
          control={control}
          render={({ field: { onChange, value } }) => (
      <ThemeProvider theme={myTheme}>
          <MUIRichTextEditor
            label="Type something here..."
            onSave={save}
            onChange={onEditorChange}
            inlineToolbar={true}
          />
        </ThemeProvider>
          )}
          />
        
        <br />
        <input type="submit" />
      </form>
    </>
  );
};

export default ReactForms;
