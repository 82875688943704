import { Helmet } from "react-helmet";
const Contact = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Title Contact</title>
        <meta
          property="og:image"
          content="https://all-frontend-assets.s3.amazonaws.com/medigrade/assets/images/og_image.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://all-frontend-assets.s3.amazonaws.com/medigrade/assets/images/og_image.jpg"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="Medigrade"></meta>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <h1>This is Contact .. </h1>
    </div>
  );
};

export default Contact;
