import { Helmet } from "react-helmet";
const Blogs = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Title Blog</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <h1>This is Blog </h1>
    </div>
  );
};

export default Blogs;
