import { Helmet } from "react-helmet";
import Axios from "axios";
import React, { useEffect, useState } from "react";
const Home = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  console.log("in home");
  let html = "11";

  useEffect(() => {
    // isLoaded = false;
    console.log(isLoaded, "setIsLoaded");
    setIsLoaded(false);
    // Axios.get("https://api.npms.io/v2/search?q=react").then((response) => {
    //   console.log(response.status, response.data.total, response.data.results);
    //   setIsLoaded(true);
    //   setItems(response.data.results);

    // https://wfr9bu9th2.execute-api.us-east-1.amazonaws.com/dev/api4/new-dashboard-report-datalist

    // });

    Axios.post(
      " https://wfr9bu9th2.execute-api.us-east-1.amazonaws.com/dev/api4/new-dashboard-report-datalist",
      {
        condition: { limit: 1000, skip: 0 },
        sort: { field: "_id", type: "desc" },
        searchcondition: { status: { $in: [8, 9, 10] } },
        secretkey: "na",
      }
    ).then((response) => {
      console.log(
        response,
        // response.status,
        // response.data.total,
        response.data.results.res
      );
      setIsLoaded(true);
      setItems(response.data.results.res);
      console.log(isLoaded, "setIsLoaded");
    });
  }, []);

  if (isLoaded)
    html = (
      <ul>
        {isLoaded &&
          items.map((item) => (
            <li key={item._id}>
              {item.batch_name} || {item.cost_per_test} || {item.doc_name}
            </li>
          ))}
      </ul>
    );
  else {
    console.log(" false ...");
    html = <span>Loading ... </span>;
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Title Home</title>
        <meta
          property="og:image"
          content="https://all-frontend-assets.s3.amazonaws.com/medigrade/assets/images/og_image.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://all-frontend-assets.s3.amazonaws.com/medigrade/assets/images/og_image.jpg"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="Medigrade"></meta>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <h1>This is Home </h1>
      {html}
    </div>
  );
};

export default Home;
