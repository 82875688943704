import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { userAdded } from "./usersSlice";

export function AddUser() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [role, setRole] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [phnum, setPhNum] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const handleFName = (e) => setFName(e.target.value);
  const handleLName = (e) => setLName(e.target.value);
  const handleRole = (e) => setRole(e.target.value);
  const handlePhNum = (e) => setPhNum(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handleCity = (e) => setCity(e.target.value);
  const handleState = (e) => setState(e.target.value);
  const handleZip = (e) => setZip(e.target.value);
  const handleCountry = (e) => setCountry(e.target.value);


  const usersAmount = useSelector((state) => state.users.entities.length);

  const handleClick = () => {
    if (fname && lname && email && role && city && state && zip && country && phnum) {
      dispatch(
        userAdded({
          id: usersAmount + 1,
          fname, lname, email, role, city, state, zip, country, phnum
        })
      );

      setError(null);
      // history.push("/");
      history("/userlist", { replace: true });
    } else {
      setError("Fill in all fields");
    }

    // setName("");
    // setEmail("");
  };

  return (
    <div className="container">
      <div className="row">
        <h1>Add user</h1>
      </div>
      <div className="row">
        <div className="three columns">
          <form onSubmit={handleClick}>
            <label htmlFor="nameInput">First Name</label>
            <input
              className="u-full-width"
              type="text"
              placeholder="Krishtern"
              id="nameInput"
              onChange={handleFName}
              value={fname}
            />

            <label htmlFor="nameInput">Last Name</label>
            <input
              className="u-full-width"
              type="text"
              placeholder="Stwert"
              id="nameInput"
              onChange={handleLName}
              value={lname}
            />

            <label htmlFor="emailInput">Email</label>
            <input
              className="u-full-width"
              type="email"
              placeholder="test@mailbox.com"
              id="emailInput"
              onChange={handleEmail}
              value={email}
            />

            <label htmlFor="RoleInput">Role</label>
            <input
              className="u-full-width"
              type="text"
              placeholder="Admin"
              id="RoleInput"
              onChange={handleRole}
              value={role}
            />

            <label htmlFor="AddressInput">City</label>
            <input
              className="u-full-width"
              type="text"
              placeholder="221B Baker Street"
              id="AddressInput"
              onChange={handleCity}
              value={city}
            />

            <label htmlFor="AddressInput">State</label>
            <input
              className="u-full-width"
              type="text"
              placeholder="Westminster"
              id="AddressInput"
              onChange={handleState}
              value={state}
            />

            <label htmlFor="AddressInput">Zip</label>
            <input
              className="u-full-width"
              type="number"
              placeholder="11111"
              id="AddressInput"
              onChange={handleZip}
              value={zip}
            />

            <label htmlFor="AddressInput">Country</label>
            <input
              className="u-full-width"
              type="text"
              placeholder="England"
              id="AddressInput"
              onChange={handleCountry}
              value={country}
            />

            <label htmlFor="PhoneInput">Phone Number</label>
            <input
              className="u-full-width"
              type="number"
              placeholder="1234567890"
              id="PhoneInput"
              onChange={handlePhNum}
              value={phnum}
            />


            {error && error}
            <button className="button-primary" type='submit'>
              Add user
            </button>
          </form>

        </div>
      </div>
    </div>
  );
}
